<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1000"
      scroll="clip"
    >
      <div class="modal-card h-screen" style="width: auto;">
        <header class="modal-card-head">
          <span class="text-base">Preview</span>
        </header>
        <div id="output-canvas" class="modal-card-body w-full p-3">
          <div class="flex justify-center w-full items-center flex-col p-0">
            <div class="w-full flex justify-between items-start flex-row">
              <span class="account-number-print-out-font font-bold"
                >HIK PARAHYANGAN</span
              >
              <span class="account-number-print-out-font font-bold"
                >Tanggal :
                {{
                  printOutData ? printOutData.letterhead.printDate : "-"
                }}</span
              >
            </div>
            <div class="w-full mt-1 flex justify-between items-start flex-row">
              <span class="account-number-print-out-font font-bold">{{
                printOutData ? printOutData.letterhead.officeName : "-"
              }}</span>
              <span class="font-bold"></span>
            </div>
            <div class="w-full mt-1 flex justify-center items-center flex-col">
              <span class="account-number-print-out-font font-bold"
                >REKAP NOMINATIF TABUNGAN BY ACCOUNT NUMBER</span
              >
              <span class="account-number-print-out-font font-bold mt-1"
                >SUKSES PER TANGGAL :
                {{
                  printOutData ? printOutData.letterhead.filteredDate : "-"
                }}</span
              >
            </div>
            <b-table
              class="mt-3"
              narrowed
              v-if="printOutData"
              :data="printOutData.tableData"
            >
              <template v-slot:empty>
                <div class="flex justify-center items-center p-3">
                  <span class="account-number-print-out-font">
                    No Data Found
                  </span>
                </div>
              </template>
              <b-table-column centered field="noUrut" label="No Urut">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.noUrut
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="namaProduk" label="Nama Produk">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.namaProduk
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="noRekening" label="No Rekening">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.noRekening
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="namaPemilik" label="Nama Pemilik">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.namaPemilik
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="cabang" label="Cabang">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.cabang ? props.row.cabang : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="produk" label="Produk">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.produk
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="alamat" label="Alamat">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.alamat ? props.row.alamat : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="noCif" label="No CIF">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.noCif
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="tglPembukuan"
                label="Tgl Pembukaan"
              >
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.tglPembukuan ? props.row.tglPembukuan : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="nisbahPercent" label="Nisbah%">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.nisbahPercent ? props.row.nisbahPercent : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="transaksiTerakhir"
                label="Transaksi Terakhir"
              >
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.transaksiTerakhir
                      ? dateToYearMonthDay(props.row.transaksiTerakhir)
                      : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="saldo" label="Saldo">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.saldo ? props.row.saldo : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="saldoRatarata"
                label="Saldo Rata Rata"
              >
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.saldoRatarata ? props.row.saldoRatarata : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="kodeAo" label="Kode AO">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.kodeAo ? props.row.kodeAo : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="namaAo" label="Nama AO">
                <template v-slot:header="{ column }">
                  <span class="account-number-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="account-number-print-out-font">{{
                    props.row.namaAo ? props.row.namaAo : "-"
                  }}</span>
                </template>
              </b-table-column>
            </b-table>
            <div
              class="mt-20 ml-4 flex justify-start align-start w-full flex-col"
            >
              <div>
                <span class="font-bold account-number-print-out-font mr-2">
                  ( Maker )
                </span>
                <span class="font-bold account-number-print-out-font mr-2">
                  ( Checker )
                </span>
                <span class="font-bold account-number-print-out-font">
                  ( Signer )
                </span>
              </div>
              <div>
                <span class="font-bold account-number-print-out-font">
                  Print By : {{ printOutData ? printOutData.printBy : "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button @click="closePreview" label="Batal" />
          <b-button
            :loading="isLoading"
            :disabled="isLoading"
            @click="onPressPrint"
            label="Print Out"
            type="is-link"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "NominatifSavingAccountAccountNumberListPdf",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: null,
    };
  },

  methods: {
    closePreview() {
      this.showModal = false;
      this.printOutData = null;
    },
    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.1,
        filename: `Rekap_Nominatif_Tabungan_By_Account_Number.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      // New Promise-based usage:
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>

<style>
.account-number-print-out-font {
  font-size: 8px;
}
</style>

import moment from "moment";
import NominatifSavingAccountAccountNumberListPdf from "@/components/page/nominatif-saving-account-account-number-list-pdf";
import vuePDF from "vue-pdf";

export default {
  name: "AccountNumber",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
  },
  components: {
    NominatifSavingAccountAccountNumberListPdf,
  },
  data() {
    return {
      isLoading: false,
      property: {
        modal: {
          showModalPreviewPDFNominatif: false,
        },
        listElement: {
          nominatif: {
            currentPage: 1,
            rows: 0,
            perPage: 10,
            message: "",
            filteredDate: "",
            downloading: false,
          },
        },
        animation: {
          isDownloadingFile: false,
          submitLoading: false,
        },
      },
      pdf: {
        src: null,
        pdfToDownload: null,
        numPages: 0,
      },
      dataForm: {
        kodeProduk: "",
        officeCode: "",
        dateTo: "",
        dateFrom: "",
        akadTypeCode: "",
      },
      options: {
        kodeProduk: [],
        officeCode: [],
        loanAkadType: [],
      },
      table: {
        header: {
          nominatif: [],
        },
        data: {
          nominatif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        officeCode: this.dataForm.officeCode,
        dateFrom: this.dataForm.dateFrom,
        dateTo: this.dataForm.dateTo,
        kodeProduk: this.dataForm.kodeProduk,
        akadTypeCode: this.dataForm.akadTypeCode,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-account/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Nominatif_Tabugan_Per_Akun.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.nominatifSavingAccountAccountNumberListPdfRef.onOpenPreview({
        tableData: this.table.data.nominatif,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: getUserPayloadFromSession.officeName,
          filteredDate: this.property.listElement.nominatif.filteredDate,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    resetTable() {
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.rows = 0;
      this.property.listElement.nominatif.currentPage = 1;
      this.property.listElement.nominatif.message = "";
    },
    // getTableNominatifAccountNumber() {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   }
    //   this.$buefy.dialog.confirm({
    //     message: "Proses Data ?",
    //     type: "is-success",
    //     onConfirm: async () => {
    //       this.property.animation.submitLoading = true;
    //       this.property.listElement.nominatif.downloading = true;
    //       this.resetTable();
    //       try {
    //         const resp = await this.$store.dispatch({
    //           type: "GET_LIST_DATA",
    //           endPoint: "report",
    //           reqUrl: "report/nominatif-account",
    //           payload: {
    //             officeCode: this.dataForm.officeCode,
    //             dateFrom: this.dataForm.dateFrom,
    //             dateTo: this.dataForm.dateTo,
    //             kodeProduk: this.dataForm.kodeProduk,
    //             page: 0,
    //             size: 10,
    //           },
    //         });
    //         console.log(resp);
    //         if (resp.data.code === "SUCCESS") {
    //           setTimeout(() => {
    //             this.property.listElement.nominatif.filteredDate = `${resp.data.data.dateFrom} - ${resp.data.data.dateTo}`;
    //             this.table.data.nominatif = resp.data.data.response.content;
    //             this.property.listElement.nominatif.rows =
    //               resp.data.data.response.totalElements;
    //           }, 1000);
    //         } else {
    //           this.property.listElement.nominatif.message = resp.data.message;
    //         }
    //       } catch (error) {
    //         this.table.data.nominatif = [];
    //         this.property.listElement.nominatif.message = error.response
    //           ? `Gagal Mengunduh Data : ${error.response.statusText}`
    //           : "Terjadi Kesalahan";
    //       } finally {
    //         setTimeout(() => {
    //           this.property.animation.submitLoading = false;
    //           this.property.listElement.nominatif.downloading = false;
    //         }, 1000);
    //       }
    //     },
    //   });
    // },

    closeModalPreviewPDFNominatif() {
      this.pdf.src = null;
      this.pdf.pdfToDownload = null;
      this.pdf.numPages = 0;
      this.property.modal.showModalPreviewPDFNominatif = false;
    },
    async handleProccessNominatif() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Data ?",
          type: "is-success",
          onConfirm: async () => {
            this.pdf.src = null;
            this.pdf.numPages = 0;
            this.pdf.pdfToDownload = null;
            this.property.animation.isDownloadingFile = true;
            try {
              const payload = {
                officeCode: this.dataForm.officeCode,
                dateFrom: this.dataForm.dateFrom,
                dateTo: this.dataForm.dateTo,
                kodeProduk: this.dataForm.kodeProduk,
                akadTypeCode: this.dataForm.akadTypeCode,
                fileType: "pdf",
              };
              const response = await this.$store.dispatch({
                type: "GET_SAVE_RESPONSE_DATA",
                endPoint: "report",
                reqUrl: "report/nominatif-account/files",
                payload: payload,
              });
              console.log("Success", response);
              console.log(response.data);
              const blob = new Blob([response.data]);
              console.log(blob);
              const objectUrl = URL.createObjectURL(blob);
              var load = vuePDF.createLoadingTask(objectUrl);
              this.pdf.pdfToDownload = objectUrl;
              this.pdf.src = load;
              this.pdf.src.promise.then((pdf) => {
                this.pdf.numPages = pdf.numPages;
              });
              this.property.modal.showModalPreviewPDFNominatif = true;
            } catch (error) {
              this.pdf.src = null;
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.isDownloadingFile = false;
              }, 1000);
            }
          },
        });
      }
    },
    appendDefaultSavingAccount() {
      this.options.kodeProduk = [{ value: "", text: "-- Pilih --" }];
      this.options.officeCode = [{ value: "", text: "-- Pilih --" }];
      this.options.loanAkadType = [{ value: "", text: "-- Pilih --" }];
    },

    async getReferenceSaving() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_TRANSACTION",
          {
            url: "saving/simple-list",
            params: {
              name: "",
              page: 0,
            },
          }
        );
        this.options.kodeProduk = [{ value: "", text: "-- Pilih --" }];
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.savingCode;
            const text = i.savingName;
            this.options.kodeProduk.push({
              text,
              value,
            });
          });
        }
      } catch (error) {}
    },
    // async getReferenceLoanAkadType() {
    //   try {
    //     const resp = await this.$store.dispatch(
    //       "GET_REFERENCE_FROM_MASTER_TRANSACTION",
    //       {
    //         url: "reference/loan-akad-type",
    //         params: {
    //           name: "",
    //           page: 0,
    //         },
    //       }
    //     );
    //     this.options.loanAkadType = [{ value: "", text: "-- Pilih --" }];
    //     if (resp.data.code === "SUCCESS") {
    //       resp.data.data.content.map((i) => {
    //         const value = i.loanAkadTypeName;
    //         const text = i.loanAkadTypeAcronym;
    //         this.options.loanAkadType.push({
    //           text,
    //           value,
    //         });
    //       });
    //     }
    //   } catch (error) {}
    // },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          this.options.officeCode = [{ value: "", text: "-- Pilih --" }];
          response.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = index.officeName;
            this.options.officeCode.push({ text, value });
          });
        });
    },
    async changePaginationNominatifAccountNumber(event) {
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-account",
          payload: {
            officeCode: this.dataForm.officeCode,
            dateFrom: this.dataForm.dateFrom,
            dateTo: this.dataForm.dateTo,
            kodeProduk: this.dataForm.kodeProduk,
            page: event - 1,
            size: this.property.listElement.nominatif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.nominatif.filteredDate = `${resp.data.data.dateFrom} - ${resp.data.data.dateTo}`;
            this.table.data.nominatif = resp.data.data.response.content;
            this.property.listElement.nominatif.rows =
              resp.data.data.response.totalElements;
          } else {
            this.table.data.nominatif = [];
            this.property.listElement.nominatif.rows = 0;
            this.property.listElement.nominatif.message = resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.table.data.nominatif = [];
          this.property.listElement.nominatif.rows = 0;
          this.property.listElement.nominatif.currentPage = 1;
          this.property.listElement.nominatif.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.nominatif.downloading = false;
        }, timeout);
      }
    },
    setDefaultOfficeCodeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.officeCode =
        getUserDataFromSession.officeCode ===
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_OFFICE_CENTRAL
          ? ""
          : getUserDataFromSession.officeCode;
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-akad-type",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.loanAkadTypeCode;
            const text = i.loanAkadTypeName;
            this.options.loanAkadType.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error.response);
      }
    },
  },

  mounted() {
    this.setDefaultOfficeCodeValue();
    this.getReferenceSaving();
    this.helperGetOfficeDataForReference();
    this.appendDefaultSavingAccount();
    this.getReferenceLoanAkadType();
  },
};
